import Vue from "vue";

import router from "./router";
import { createPinia, PiniaVuePlugin } from 'pinia'
import store from "./store";
import configs from "./configs";
import vuetify from "./plugins/vuetify";

import "@/plugins/axios";
import "./plugins/vee";
import "./plugins/vuetoastification";
import "./registerServiceWorker";

import { filters, errorFunction } from "@/utils";
import setApiRoutes from "./helpers/setApiRoutes";

import App from "./App.vue";
import { VueMaskDirective } from "v-mask";
import VueGtag from "vue-gtag";

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(
  VueGtag,
  {
    config: { id: "G-SVPP3PSJ0V" },
  },
  router
);

Vue.filter("dateFilter", filters.dateFilter);
Vue.filter("floatFilter", filters.floatFilter);
Vue.filter("cpfCnpjFilter", filters.cpfCnpjFilter);
Vue.filter("zipCodeFilter", filters.zipCodeFilter);

Vue.directive("mask", VueMaskDirective);

setApiRoutes(Vue, configs.url, configs.env);

Vue.config.productionTip = false;
Vue.prototype.$fnError = errorFunction;
Vue.prototype.$vuetify = vuetify;

new Vue({
  router,
  pinia,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
