import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import routes from "./pathes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(Meta);

router.beforeEach((to, from, next) => {
  if (to.name === "approval-proposal") {
    if (
      to.query.apr &&
      to.query.cl &&
      to.query.em &&
      to.query.lg &&
      to.query.r &&
      to.query.id
    ) {
      return next();
    }
  }
  if (to.name === "approval-requisition") {
    if (
      to.query.apr &&
      to.query.cl &&
      to.query.em &&
      to.query.lg &&
      to.query.r &&
      to.query.id
    ) {
      return next();
    }
  }

  if (to.name === "Login") {
    if (store.getters["auth/isAuthenticated"]) {
      return router.push("/");
    }
  } else {
    if (!store.getters["auth/isAuthenticated"]) {
      return router.push("/login");
    }
  }
  next();
});

const defaultTitle = "Atlas ATMO - Znap Technologies";
router.afterEach((to) => {
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${to.meta.title} - ${defaultTitle}`;
      return;
    }

    let getModule = store.getters["auth/getModules"] || [];
    let getMenu = store.getters["auth/getMenu"] || [];

    if (getMenu.length && getModule.length) {
      let submenu = getMenu.find((m) => {
        if (m.frontend_route_path) {
          return (
            to.fullPath.split("/")[2] === m.frontend_route_path.replace("/", "")
          );
        }
      });

      let module = getModule.find((m) => {
        if (m.path) {
          return to.fullPath.split("/")[1] === m.path.replace("/", "");
        }
      });

      if (submenu && module) {
        const { label } = submenu;
        document.title = `${label} - ${module.label} ${defaultTitle}`;
      } else {
        document.title = defaultTitle;
      }
    } else {
      document.title = defaultTitle;
    }
  });
});

export default router;
