
const configs = {
  id: 1,
  url: "atlas",
  system: "atmo",
  module: "ZNAP_SECURITY",
  language: "pt",
  port: 80,
  theme: "znap.js",
  env: "prod",
};

export default configs;
