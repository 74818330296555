import Vue from "vue";
import jwt_decode from "jwt-decode";
import router from "@/router";

const getDefaultState = () => {
  return {
    token: "",
    iat: "",
    exp: null,
    hash: "",
    user: {},
    id: null,
    status: "",
    modules: [],
    menu: [],
    permissions: [],
    groups: [],
    system: "",
    sessionExpiredDialog: false,
    reloadDialog: false,
  };
};

const getters = {
  getToken: (state) => state.token,
  getTokenExp: (state) => state.exp,
  getHash: (state) => state.hash,
  isAuthenticated: (state) => !!state.token,
  getStatus: (state) => state.status,
  getMenu: (state) => state.menu,
  getModules: (state) => state.modules,
  getUser: (state) => state.user,
  getSessionExpiredDialog: (state) => state.sessionExpiredDialog,
  getReloadDialog: (state) => state.reloadDialog,
  getPermissions: (state) => state.permissions,
  getGroups: (state) => state.groups,
};

const mutations = {
  setAuth(state, token) {
    state.token = token;
    state.iat = jwt_decode(token).iat;
    state.exp = jwt_decode(token).exp;
  },

  setUser(state, payload) {
    state.user = payload;
  },

  setSessionExpiredDialog(state, payload) {
    state.sessionExpiredDialog = payload;
  },

  setReloadDialog(state, payload) {
    state.reloadDialog = payload;
  },

  setToken(state, token) {
    state.token = token;
  },

  setTokenIat(state, iat) {
    state.iat = iat;
  },

  setTokenExp(state, exp) {
    state.exp = exp;
  },

  setHash(state, hash) {
    state.hash = hash;
  },

  setModules(state, modules) {
    state.modules = modules;
  },

  setMenu(state, payload) {
    state.menu = payload;
  },

  setPermissions(state, permissions) {
    state.permissions = permissions;
  },

  setGroups(state, groups) {
    state.groups = groups;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async loadGroups({ state, commit }, id) {
    try {
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_user",
              operator: "=",
              value: id,
            },
          ],
        },
      };

      const res = await Vue.prototype.$http.post(
        Vue.prototype.$ipSecurity + "user-group/list",
        payload
      );
      if (res) {
        commit("setGroups", res.data.rows);
      }
    } catch (err) {
      state.status = "error";
      throw err;
    }
  },

  async authenticate({ state, commit, dispatch }, user) {
    try {
      state.status = "loading";

      const payload = {
        email: user.email,
        password: user.password,
      };

      const res = await Vue.prototype.$http.post(
        Vue.prototype.$ipUser + "user/login",
        payload
      );
      if (res) {
        commit("setHash", res.data.hash);
        state.id = res.data.id[0];
        commit("setPermissions", {
          transactionCodes: res.data.transactionCodes,
        });
        dispatch("loadGroups", state.id);
        dispatch("setUser", state.id);
        commit("setAuth", res.data.token);
      }

      state.status = "success";
      return res;
    } catch (err) {
      state.status = "error";
      throw err;
    }
  },

  async hashAuthenticate({ state, commit, dispatch }, hash) {
    commit("setHash", hash);

    try {
      const res = await Vue.prototype.$http.post(
        Vue.prototype.$ipUser + "user/hash/login",
        { hash }
      );
      if (res) {
        state.id = res.data.id[0];
        dispatch("setUser", state.id);
        commit("setAuth", res.data.token);
        return "success";
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async setUser({ commit }, userId) {
    try {
      const res = await Vue.prototype.$http.post(
        Vue.prototype.$ipUser + `user/list/${userId}`,
        {}
      );
      if (res) {
        const user = res.data.rows[0];
        commit("setUser", user);
      }
    } catch (err) {
      console.log(err);
    }
  },

  async setToken({ commit }, token) {
    commit("setToken", token);
    commit("setTokenIat", jwt_decode(token).iat);
    commit("setTokenExp", jwt_decode(token).exp);

    return token;
  },

  setTableObject({ commit }, items) {
    commit("setMenu", items);
  },

  setModulesObject({ commit }, items) {
    commit("setModules", items);
  },

  async logout({ commit }) {
    console.log('chamou')
    try {
      await Vue.prototype.$http.patch(
        Vue.prototype.$ipUser + "user/update-hash",
        { hash: null }
      );
    } catch (err) {
      console.log(err);
    } finally {
      commit("resetState");
      router.push("/login");
    }
  },
};

export default {
  state: () => getDefaultState(),
  getters,
  mutations,
  actions,
};
