const getDefaultState = () => {
  return {
      shipmentStore: false,
      returnStore: false,
      proposalId: null
  };
};

const getters = {
  isShipmentStore: state => state.shipmentStore,
  isReturnStore: state => state.returnStore,
  getProposalId: state => state.proposalId
}

const mutations = {
  updateShipment(state, status) {
      state.shipmentStore = status
  },
  updateReturn(state, status) {
      state.returnStore = status
  },

  updateProposalId(state, id) {
      state.proposalId = id
  }
}

const actions = {
  setShipmentStatus({ commit }, status) {
      commit('updateShipment', status)
  },
  setReturnStatus({ commit }, status) {
      commit('updateReturn', status)
  },

  setProposalId({ commit }, id) {
      commit('updateProposalId', id)
  }
}

export default {
  state: () => getDefaultState(),
  getters,
  mutations,
  actions
}
