const getDefaultState = () => {
  return {
      isFlagged: 0,
      sendApprovalEmail: false
  }
};

const getters = {
  isFlagged: state => state.isFlagged,
  sendApprovalEmail: state => state.sendApprovalEmail
}

const mutations = {
  updateFlaggedStatus(state, status) {
      state.isFlagged = status;
  },

  updateSendApprovalEmail(state, value) {
      state.sendApprovalEmail = value;
  }
}

const actions = {
  setFlaggedStatus({ commit }, status) {
      commit('updateFlaggedStatus', status);
  },

  setSendApprovalEmail({ commit }, value) {
      commit('updateSendApprovalEmail', value);
  }
}

export default {
  state: () => getDefaultState(),
  getters,
  mutations,
  actions
}
