<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge
          avatar
          bottom
          :color="sessionBadgeColor"
          offset-x="18"
          offset-y="18"
        >
          <template v-if="getUser">
            <v-avatar v-if="getUser.photo">
              <v-img :src="getUser.photo" :aspect-ratio="1 / 1">
                <template v-slot:placeholder>
                  <v-skeleton-loader type="avatar"></v-skeleton-loader>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar v-else>
              <v-icon color="white" class="text-h3">mdi-account-circle</v-icon>
            </v-avatar>
          </template>
          <v-avatar v-else>
            <v-icon color="white" class="text-h3">mdi-account-circle</v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <v-sheet max-width="350px">
      <v-list>
        <v-list-item-group>
          <v-list-item
            @click="
              $route.path !== '/profile' ? $router.push('/profile') : false
            "
          >
            <v-list-item-icon class="mr-4">
              <template v-if="getUser">
                <v-avatar v-if="getUser.photo">
                  <v-img
                    :src="getUser.photo"
                    :alt="'Foto de ' + getUser.name"
                    :aspect-ratio="1 / 1"
                  />
                </v-avatar>
                <v-icon v-else color="gray darken-2" x-large
                  >mdi-account-circle</v-icon
                >
              </template>
              <v-icon v-else color="gray darken-2" x-large
                >mdi-account-circle</v-icon
              >
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-h6">{{
                getUser ? getUser.name : ""
              }}</v-list-item-title>
              <v-list-item-title class="text-caption">{{
                getUser ? getUser.email : ""
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-row class="py-2 px-4">
            <v-col class="d-flex align-center">
              <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>
              <span class="text-caption">{{ tokenExp }}</span>
              <v-spacer></v-spacer>
              <v-btn x-small text color="primary" @click="renewSession()">
                Renovar
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-list-item @click="logout">
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "UserInfoMenu",
  data() {
    return {
      sessionBadgeColor: "success",
      now: new Date(),
    };
  },
  computed: {
    ...mapGetters("auth", ["getTokenExp", "getUser", "getHash"]),

    tokenExp() {
      let tokenExp = "";

      if (this.getTokenExp && !this.isValidToken(this.getTokenExp)) {
        if (
          window.location.pathname.includes("/approval-proposal") ||
          window.location.pathname.includes("/approval-requisition")
        ) {
          this.setSessionExpiredDialog(false);
        } else {
          this.setSessionExpiredDialog(true);
        }

        tokenExp = "Sessão expirada";
        return;
      }

      let now = moment(this.now, "hh:mm:ss");
      let exp = moment.unix(this.getTokenExp, "hh:mm:ss");
      let duration = moment.duration(exp.diff(now))._data;

      let hours =
        duration.hours.toString().length < 2
          ? `0${duration.hours}`
          : `${duration.hours}`;
      let minutes =
        duration.minutes.toString().length < 2
          ? `0${duration.minutes}`
          : `${duration.minutes}`;
      let seconds =
        duration.seconds.toString().length < 2
          ? `0${duration.seconds}`
          : `${duration.seconds}`;
      let time = `${hours}:${minutes}:${seconds}`;

      this.setSessionBadgeColor(hours, minutes);

      tokenExp = `Sessão expira em ${time}`;

      return tokenExp;
    },
  },
  methods: {
    ...mapMutations("auth", ["setSessionExpiredDialog"]),
    ...mapActions("auth", ["hashAuthenticate", "logout"]),

    async renewSession() {
      let hash = this.getHash;

      const auth = await this.hashAuthenticate(hash);
      if (auth === "success") {
        this.$toast.success("Sessão renovada com sucesso");
      } else {
        let err = auth;
        this.$fnError(err);
      }
    },

    isValidToken(tokenExp) {
      let now = Math.floor(Date.now() / 1000);
      return now - tokenExp <= 0;
    },

    setSessionBadgeColor(hours, minutes) {
      if (!hours || !minutes) {
        return (this.sessionBadgeColor = "error");
      }

      let h = parseInt(hours);
      let m = parseInt(minutes);

      if (h === 0) {
        if (m >= 10) {
          this.sessionBadgeColor = "success";
        } else if (m < 10 && m > 5) {
          this.sessionBadgeColor = "warning";
        } else if (m < 5) {
          this.sessionBadgeColor = "error";
        }
      } else {
        this.sessionBadgeColor = "success";
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval();
  },
};
</script>

<style lang="scss" scoped>
</style>