<template>
  <v-layout v-resize="onResize" v-show="show">
    <v-app-bar dark elevation="2" class="pr-2">
      <v-app-bar-nav-icon
        data-testid="open-nav-bar-button"
        v-if="$route.path !== '/' && $route.path !== '/profile'"
        @click.stop="drawer = !drawer"
        color="white"
        class="my-2 ml-n2 mr-2"
        min-width="48px"
        min-height="48px"
      ></v-app-bar-nav-icon>

      <div
        v-if="!isMobile"
        @click="$router.push('/')"
        class="d-flex align-center"
      >
        <v-img
          class="mr-2"
          max-width="56"
          max-height="56"
          src="/img/logo.webp"
        ></v-img>
        <v-img
          max-width="72"
          max-height="34"
          src="/img/logo_only_name.webp"
        ></v-img>
      </div>

      <v-divider
        v-if="isHomol && !isMobile"
        class="mx-4"
        vertical
        inset
      ></v-divider>

      <span
        v-if="isHomol"
        class="text-uppercase font-weight-bold white--text"
        :class="!isMobile ? 'headline' : ''"
      >
        {{ isMobile ? "SB" : "Sandbox" }}
      </span>

      <v-spacer></v-spacer>

      <v-menu
        v-if="selectedModule"
        bottom
        offset-y
        style="z-index: 1000 !important"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="mr-2" color="white">
            <v-icon color="white" class="mx-2">{{
              selectedModule.icon
            }}</v-icon>
            <span>{{ selectedModule.label }}</span>
          </v-btn>
        </template>

        <v-list style="overflow-x: hidden">
          <v-list-item-group
            v-model="selectionMenu"
            active-class="primary--text text--accent-4"
          >
            <a
              v-for="clientModule in modules"
              :key="clientModule.label"
              class="text-decoration-none"
              :href="clientModule.link"
            >
              <v-layout align-center>
                <v-flex xs11>
                  <v-list-item
                    @click="
                      $route.path.split('/')[1] !==
                      clientModule.path.split('/')[1]
                        ? navigateMenu(clientModule)
                        : false
                    "
                    @click.prevent="
                      $nextTick(() => {
                        selectionMenu = null;
                      })
                    "
                    :class="
                      $route.path.split('/')[1] ===
                      clientModule.path.split('/')[1]
                        ? 'primary--text'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="primary"> {{ clientModule.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ clientModule.label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs1>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-layout justify-center>
                        <a
                          :href="clientModule.link"
                          target="_blank"
                          class="text-decoration-none"
                          v-on="on"
                        >
                          <v-icon
                            class="px-2"
                            color="primary"
                            style="margin-left: -8px !important"
                            small
                            >mdi-open-in-new</v-icon
                          >
                        </a>
                      </v-layout>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </a>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <UserInfoMenu
        v-if="windowSize.x > 1"
        bottom
        offset-y
        style="z-index: 5000"
      />

      <v-divider
        v-if="windowSize.x > 600"
        class="ml-6 mr-4"
        vertical
        inset
      ></v-divider>

      <a
        v-if="windowSize.x > 600"
        href="https://znap.com.br/"
        style="text-decoration: none"
      >
        <v-img src="/img/znap-home.png" max-width="95" max-height="50"></v-img>
      </a>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary app dark width="500">
      <v-container fluid fill-height class="pa-0 ma-0">
        <v-layout column>
          <div class="d-flex">
            <v-app-bar-nav-icon
              data-testid="close-nav-bar-button"
              @click.stop="drawer = !drawer"
              color="white"
              class="my-2 ml-1"
              min-width="48px"
              min-height="48px"
            ></v-app-bar-nav-icon>

            <v-img
              @click="$router.push('/')"
              class="mr-2 mt-2"
              max-width="56"
              max-height="54"
              src="/img/logo.webp"
            ></v-img>

            <v-img
              @click="$router.push('/')"
              class="mt-4"
              max-width="85"
              max-height="38"
              src="/img/logo_only_name.webp"
            ></v-img>
          </div>

          <v-divider></v-divider>

          <v-list>
            <div v-for="item in parents" :key="item.transactionCode">
              <v-list-item
                @click="handleParentMenuClick(item)"
                :class="{
                  'v-list-item--active': item.menus
                    ? childIsSelected(item)
                    : parentIsSelected(item),
                }"
                data-testid="menu-list-item"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action
                  v-if="!item.menus"
                  @click.stop="openInNewTab(item)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon>
                        <v-icon v-on="on" color="white" small>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>

              <v-expand-transition>
                <v-list v-show="item.expand" class="ml-14">
                  <v-list-item
                    @click.prevent="navigate(menu)"
                    v-for="menu in item.menus"
                    :key="menu.transactionCode"
                    class="text-decoration-none"
                    active-class=""
                  >
                    <v-list-item-icon
                      v-if="
                        $route.path ===
                        `/${currentModule}${menu.frontend_route_path}`
                      "
                      class="mx-0"
                    >
                      <v-icon
                        color="primary"
                        style="margin-left: -8px !important"
                      >
                        mdi-menu-right
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text-subtitle-2 font-weight-regular white--text"
                        data-testid="menu-list-submenu-item"
                      >
                        {{ menu.label }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action @click.stop="openInNewTab(menu)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon>
                            <v-icon v-on="on" color="white" small>
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Abrir em nova aba</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </div>
          </v-list>

          <v-spacer></v-spacer>

          <v-footer color="transparent">
            <v-layout align-center justify-center>
              <p class="text-caption text-center white--text">
                v. {{ appVersion }}
              </p>
            </v-layout>
          </v-footer>
        </v-layout>
      </v-container>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import configs from "@/configs";
import packageJsonProps from "../../package";
import UserInfoMenu from "./znap/molecules/NavBar/UserInfoMenu.vue";

export default {
  name: "AppBar",

  components: { UserInfoMenu },

  data() {
    return {
      windowSize: { x: 0, y: 0 },
      menuItems: [{ text: this.$vuetify.lang.t("$vuetify.logout"), link: "/" }],

      modules: [],

      selectionMenu: null,

      isHomol: false,

      show: true,

      drawer: false,
      parents: [],
      appVersion: packageJsonProps.version,
    };
  },

  computed: {
    ...mapGetters("auth", ["getTokenExp", "getUser", "getHash"]),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    selectedModule() {
      if (!this.modules.length) {
        return null;
      }

      const current = this.modules.find((item) => {
        if (item.path) {
          const path = item.path.replaceAll("/", "");
          return path === this.currentModule;
        }
      });

      return current ? { icon: current.icon, label: current.label } : null;
    },

    currentModule() {
      return this.$route.path.split("/")[1];
    },

    currentRoutePath() {
      return this.$route.path;
    },
  },

  watch: {
    getUser: {
      immediate: true,
      handler(val) {
        if (val.id_user) {
          this.listModules();
        }
      },
    },

    currentModule: {
      immediate: true,
      handler(val) {
        if (val) {
          this.listMenu();
        }
      },
    },

    currentRoutePath: {
      immediate: true,
      handler(route) {
        const mainViews = [
          "/security",
          "/masterdata",
          // "/proposals",
          "/purchases",
          "/approvalflow",
          // "/stock",
        ];

        if (mainViews.includes(route)) {
          this.drawer = true;
        }

        if (route === "/stock") {
          this.$router.push({ name: "stock-event-calendar" });
        }

        if (route === "/proposals") {
          this.$router.push({ name: "proposal-event-calendar" });
        }
      },
    },
  },

  created() {
    this.$root.$on("hideAppBar", ($event) => (this.show = !$event));
  },

  mounted() {
    if (configs.env === "homol") {
      this.isHomol = true;
    }
  },

  methods: {
    ...mapActions("auth", ["setHash", "setModulesObject", "setTableObject"]),

    async listModules() {
      if (
        window.location.pathname !== "/approval-proposal" &&
        window.location.pathname !== "/approval-requisition"
      ) {
        const payload = {
          module: "ZNAP_PORTAL",
        };

        const res = await this.$http.post(this.$ipUser + "user/menu", payload);
        if (res) {
          this.modules = res.data;

          this.setModulesObject(this.modules);

          let url = null;
          if (window.location.href.indexOf(":80") == -1) {
            url = `https://${configs.url}.atmodigital.com`;
          } else {
            url = "http://localhost:8080";
          }

          this.modules.forEach((module) => {
            module.link = url + module.path;
          });
        }
      }
    },

    navigateMenu(menu) {
      this.$router.push(menu.path);
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    // drawer
    setPayloadModule(moduleName) {
      if (moduleName.includes("security")) {
        return "ZNAP_SECURITY";
      }

      if (moduleName.includes("masterdata")) {
        return "ZNAP_MASTER_DATA";
      }

      if (moduleName.includes("proposals")) {
        return "ZNAP_PROPOSAL";
      }

      if (moduleName.includes("purchases")) {
        return "ZNAP_PURCHASE";
      }

      if (moduleName.includes("approvalflow")) {
        return "ZNAP_APPROVAL_FLOW";
      }

      if (moduleName.includes("stock")) {
        return "ZNAP_STOCK";
      }
    },

    async listMenu() {
      this.parents = [];

      const payload = {
        module: this.setPayloadModule(this.currentModule),
      };

      const res = await this.$http.post(this.$ipUser + "user/menu", payload);
      if (res) {
        const items = res.data.filter(
          (item) => item.transactionCode !== "LOGIN" && !item.hideMenu
        );

        const childs = items.filter(
          (i) => !i.transactionCode.includes("MENU_GROUP")
        );

        const hasOffParentItems = items.filter(
          (i) => i.offParent && !i.transactionCode.includes("MENU_GROUP")
        );

        // console.log('items', items)
        // console.log('childs', childs)
        // console.log('hasOffParentItems', hasOffParentItems)

        if (items.length !== childs.length) {
          // menu agrupado
          this.parents = items
            .filter((i) => i.transactionCode.includes("MENU_GROUP"))
            .map((p) => {
              return {
                ...p,
                expand: false,
                menus: childs.filter(
                  (c) => c.id_transaction_parent === p.id_transaction_parent
                ),
              };
            });
        } else {
          // menu não agrupado
          this.parents = items;
        }

        if (hasOffParentItems) {
          this.parents.unshift(...hasOffParentItems);
        }

        this.setTableObject(items);

        // console.log('this.parents', this.parents)
      }
    },

    navigate(menu) {
      if ("/" + this.$route.path.split("/")[2] !== menu.frontend_route_path) {
        this.$router.push({ name: menu.frontend_route_path.substr(1) });
        this.drawer = false;
        this.parents = this.parents.map((p) => {
          return {
            ...p,
            expand: false,
          };
        });
      }
    },

    openInNewTab(menu) {
      let url = null;
      if (window.location.href.includes(":80")) {
        url = "http://localhost:8080/";
      } else {
        url = `https://${configs.url}.atmodigital.com/`;
      }

      window.open(
        `${url}${this.currentModule}${menu.frontend_route_path}`,
        "_blank"
      );
    },

    childIsSelected(parent) {
      const routePath = this.$route.path.split("/");
      if (routePath.length < 3) {
        return false;
      }

      const found = parent.menus.find(
        (c) => c.frontend_route_path === `/${routePath[2]}`
      );

      return !!found;
    },

    parentIsSelected(parent) {
      return (
        this.$route.path ===
        `/${this.currentModule}${parent.frontend_route_path}`
      );
    },

    handleParentMenuClick(item) {
      if (item.menus) {
        // menu agrupado
        item.expand = !item.expand;
      } else {
        // menu não agrupado
        this.$router.push(`/${this.currentModule}${item.frontend_route_path}`);
      }
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: gray !important;
}
</style>