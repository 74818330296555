const getDefaultState = () => {
  return {
    proposalBackup: null,
  };
};

const getters = {
  getProposalBackup: (state) => state.proposalBackup,
};

const mutations = {
  setProposalBackup(state, savePayload) {
    state.proposalBackup = savePayload;
  },
};

const actions = {};

export default {
  state: () => getDefaultState(),
  getters,
  mutations,
  actions,
};
